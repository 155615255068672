import React, { useState } from "react";
import { Button, Typography, Grid, IconButton, TextField, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './style.css';
 
const Modal = ({ toggleModal }) => { 

    return (
        <div>
            <div className="modal">
                <div className="modal-dialog">
                    <div className="modal-header">
                        <h3 className='modal-title'>Ваше обращение отправлено</h3>
                        <IconButton className="modal-close" onClick={() => toggleModal(false)}> <CloseIcon/> </IconButton>
                    </div>
                    <div className="modal-body">
                        <div className="modal-content">
                            <Typography>Спасибо за обращение в компанию RexTeam. Мы ответим Вам в ближайшее время.</Typography>
                        </div>
                    </div>
                    <footer className="modal-footer">
                        <Button className='button' onClick={() => {toggleModal(false)}} variant="contained">Закрыть</Button>
                    </footer>
                </div>
            </div>
        </div>
    );
}
 
 
export default Modal;