import React, {useState, useRef, useEffect} from 'react';
import { useLocation } from 'react-router-dom'

import Header from '../Header'
import Footer from '../Footer'
import '../../App.css';


import cat from '../../pic/kot.jpg';
import kamchatka from '../../pic/kamchatka.jpg';
import russia from '../../pic/russia.jpg';
import { Button, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, Alert } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from '../../components/Modal';
import emailjs from '@emailjs/browser';
import Mobile from '../../components/Mobile';
import About from '../About';


function Main({param}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const [alert, setAlert] = useState(false);
	const [price, setPrice] = useState(false);
	const form = useRef();
	const modalRef = useRef();
	const feedback = useRef();
	const services = useRef();
	// const about = useRef();
	const aboutRec = useRef();
	const [showModal, setShowModal] = useState(false);

	const windowWidth = useRef(window.innerWidth);
	
    const { pathname, hash, key } = useLocation()

	const onKeydown = ({ key }) => {
		switch (key) {
		  case 'Escape':
			setShowModal(false);
			break
		  default: break
		}
	  }

	// const handleClickOutside = async(e) => {
    //     if (typeof modalRef.current !== 'undefined' && !modalRef.current.contains(e.target)) {
    //         setShowModal(false);
    //     }
    // };
	
	// useEffect(() => {
	// 	document.addEventListener('keydown', onKeydown)
	// 	return () => document.removeEventListener('keydown', onKeydown)
	//   })

	// useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // });

    useEffect(() => {
        if (hash === '') window.scrollTo(0, 0)
        else {
          setTimeout(() => {
            const id = hash.replace('#', '')
            const element = document.getElementById(id)
            if (element) {
              element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth',
              })
            }
          }, 0)
        }
      }, [pathname, hash, key])

	const sendEmail = async () => {
		// setShowModal(true);
		if (name !== "" && email !== "" && comment !== ""){
			emailjs.sendForm('service_r0oujwz', 'template_77g0m8n', form.current, 'ic-g88gRWLxMcHbiq')
			.then((result) => {
				setName("");
				setEmail("");
				setComment("");
				setShowModal(true);
			}, (error) => {
				console.log(error.text);
			});
		} else {
			setAlert(true);
		}
		
	  }

    const AboutComp = React.forwardRef((props, ref) => (
        <div className='block' ref={ref}>
            <div className='block-item'>
                <Typography variant='h4'>О нас</Typography>
            </div>
            <div className='block-item-text'>
                <Typography>Мы молодая, развивающаяся команда специалистов, готовые оказать консультацию и выполнить работы в сфере информационных технологий. </Typography>
            </div>
            <div className='block-item-text'>
                <Typography>Имеем опыт внедрения крупной распределенной информационной системы в сфере здравоохранения Камчатского края. Работаем с видеонаблюдением, организовываем и поддерживаем облачные сервисы, 
                    разрабатываем сайты под ключ. </Typography>
            </div>
        </div>
    ));
    const about = React.createRef();

	return (


	<div className="App">
		{Number(windowWidth.current) > 1100 ? 
			<div>
                <Header about={about} services={services} feedback={feedback}/>
                
                    <div className='main'>
                        <div className='banner'>
                            <div className='banner-items'>
                                <Typography className='banner-font'>ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ <br/> НАША ПОМОЩЬ В ВАШЕМ РАЗВИТИИ</Typography>
                                <img src={cat} alt="RexTeam" className='pic'/>
                            </div>
                        </div>
                        {param == "main" ? 
                        <div className='main'>
                            <div className='block' id="about">
                                <div className='block-item'>
                                    <Typography variant='h4'>О нас</Typography>
                                </div>
                                <div className='block-item-text'>
                                    <Typography>Мы молодая, развивающаяся команда специалистов, готовые оказать консультацию и выполнить работы в сфере информационных технологий. </Typography>
                                </div>
                                <div className='block-item-text'>
                                    <Typography>Имеем опыт внедрения крупной распределенной информационной системы в сфере здравоохранения Камчатского края. Работаем с видеонаблюдением, организовываем и поддерживаем облачные сервисы, 
                                        разрабатываем сайты под ключ. </Typography>
                                </div>
                            </div>
                            <div className="block" id="services">
                                <Typography variant='h4'>Услуги</Typography>
                                <div className='block-item'>
                                    <img src={kamchatka} alt="RexTeam" className='block-item-pic'/>
                                    <div>
                                        <Typography>Мы оказываем услуги по внедрению и развитию информационных технологий на Камчатке.</Typography>
                                        <List>
                                            <ListItem>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Обеспечение работы сервисов предприятия.</ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Монтаж и сопровождение систем видеонаблюдения.</ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Настройка и сопровождение работы серверного и компьютерного оборудования.</ListItemText>
                                            </ListItem>
                                        </List>
                                    </div>
                                </div>
                                <div className='block-item'>
                                    <div>
                                        <Typography>Работаем по всей России для реализации проектов:</Typography>
                                        <List>
                                            <ListItem>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Разработка и сопровождение сайтов, Telegram и ВК - ботов.</ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Настройка и сопровождение работы серверного оборудования.</ListItemText>
                                            </ListItem>
                                            {/* {price ? 
                                            <ListItem onClick={() => setPrice((prev) => !prev)}>
                                                <ListItemIcon><ExpandMoreIcon fontSize='large'/></ListItemIcon>
                                                <ListItemText>Услуга связи для наших абонентов<p/>
                                                    <Typography fontSize={"10pt"}>Некоторым нашим абонентам необходима услуга связи между друг другом. Чаще всего это пользователи компьютерных игр, которые желают поиграть со своими друзьями по сети, однако сделать это через сервер не представляется возможным. В связи с этим, абоненты обращаются к нам за помощью и мы обеспечиваем им связь через наш сервер. <p/> 
                                                        Стоимость услуги:<br/>
                                                        85 рублей за 1 месяц<br/>
                                                        82 рубля за 3 месяца<br/>
                                                        79 рублей за 6 месяцев<br/>
                                                        75 рублей за 1 год.
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem> :  */}
                                            <ListItem onClick={() => setPrice((prev) => !prev)}>
                                                <ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
                                                <ListItemText>Услуга связи для наших абонентов</ListItemText>
                                            </ListItem>
                                        {/* } */}
                                        </List>
                                    </div>
                                    <img src={russia} alt="RexTeam" className='block-item-pic'/>
                                </div>
                            </div>
                            <div className='block' id="feedback">	
                                <Typography variant='h4'>Обратная связь</Typography>
                                <Typography variant='h6'>Напишите нам и мы с Вами свяжемся в ближайшее время</Typography>
                                <form ref={form} className='block-feedback'>
                                        <div className='block-feedback-item'>
                                            <Typography sx={{fontWeight: 'bold'}}>Ваше имя:</Typography>
                                            <TextField fullWidth className='block-feedback-textfield' name='user_name' value={name} onChange={(e) => setName(e.target.value)}></TextField>
                                        </div>
                                        <div className='block-feedback-item'>
                                            <Typography sx={{fontWeight: 'bold'}}>e-mail:</Typography>
                                            <TextField fullWidth className='block-feedback-textfield' name='user_email' value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
                                        </div>
                                        <div className='block-feedback-item'>
                                            <Typography sx={{fontWeight: 'bold'}} >Комментарий:</Typography>
                                            <TextField fullWidth multiline rows={6} className='block-feedback-textfield' name="message" value={comment} onChange={(e) => setComment(e.target.value)}></TextField>
                                        </div>
                                        {alert && <Alert severity='error'>Заполните все поля для отправки сообщения.</Alert>}

                                        <Button variant='contained' className='button' onClick={sendEmail} >Отправить</Button>
                                    <div ref={modalRef}>
                                        {showModal && <Modal toggleModal={setShowModal}/>}
                                    </div>
                                </form>
                            </div>
                        </div> :
                        <div className='block'>	
                            <About/>
                        </div>
                    }
                </div>
		<Footer about={about} services={services} feedback={feedback}/>
		</div>
		: <Mobile param={param}/>}
	</div>

	);

}

export default Main;
