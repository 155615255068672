import React from 'react';
import { IconButton, Typography} from '@mui/material';

import logoNameColor from '../../pic/logo-name-green.png';
import logoGreen from '../../pic/logo-green.png';
import ugol from '../../pic/ugol.png';
import ugol2 from '../../pic/ugol2.png';

import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';

function Footer( {about, services, feedback} ) {
    return (
        <footer className='footer-background'>
			<img src={ugol} alt="RexTeam" className='footer-logo'/>

            <div className='footer'>
                <div className='footer-block'>
                    <div className='footer-block-item'>
                        <div className='logo-div'>
                            <img src={logoGreen} alt="RexTeam" className='footer-logo'/>
                            <img src={logoNameColor} alt="" className='footer-logo-name'/>
                        </div>	
                    </div>
                </div>
                <div className='footer-block' >
                    <div className='footer-block-item-a'>
                        <Link className='font a' to='/#about'>О нас</Link>
                    </div>
                    <div className='footer-block-item-a'  >
                        <Link className='font a' to='/#feedback'>Обратная связь</Link>
                    </div>
                </div>
                <div className='footer-block'>
                    <div className='footer-block-item-a' >
                        <Link className='font a' to='/#services'>Услуги</Link>
                    </div>
                    <div className='footer-block-item-a' >
                        <Link className='font a' to='/about'>Реквизиты</Link>
                    </div>
                    <div className='footer-block-item'>
                        <a href="/docs/oferta.pdf" download className='a'>Договор оферты</a>
                    </div>
                </div>
                <div className='logo-contact'>
                    <a href="tel:+74152306083" className='a'><IconButton className='no-padding'><CallIcon className='icon'/><Typography className='icon'>306-083</Typography></IconButton></a>
                    <a href="https://wa.me/+79960344084"><IconButton className='no-padding'><WhatsAppIcon className='icon'/><Typography className='icon'>+7-996-034-40-84</Typography></IconButton></a>
                    <a href="mailto:main@rexteam.ru"><IconButton className='no-padding'><MailOutlineIcon className='icon'/><Typography className='icon'>main@rexteam.ru</Typography></IconButton></a>
                </div>
            </div> 
			<div className='ugol2'>
				<img src={ugol2} alt="RexTeam" className='footer-logo'/>
			</div>
			
        </footer>
    )
}

export default Footer;