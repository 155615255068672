import React, {useState, useRef, useEffect} from 'react';
import { Routes, Route, useNavigate, BrowserRouter } from "react-router-dom";

import './App.css';
import Main from './components/Main'
import About from './components/About'

import logo from './logo.png';
import logoName from './logo_name.png';
import logoNameColor from './pic/logo-name-green.png';
import logoGreen from './pic/logo-green.png';
import ugol from './pic/ugol.png';
import ugol2 from './pic/ugol2.png';
import cat from './pic/kot.jpg';
import kamchatka from './pic/kamchatka.jpg';
import russia from './pic/russia.jpg';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, Alert } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from './components/Modal';
import emailjs from '@emailjs/browser';
import Mobile from './components/Mobile';


function App() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const [alert, setAlert] = useState(false);
	const [price, setPrice] = useState(false);
	const form = useRef();
	const modalRef = useRef();
	const feedback = useRef();
	const services = useRef();
	const about = useRef();
	const [showModal, setShowModal] = useState(false);

	const windowWidth = useRef(window.innerWidth);
	
	const onKeydown = ({ key }) => {
		switch (key) {
		  case 'Escape':
			setShowModal(false);
			break
		  default: break
		}
	  }

	const handleClickOutside = async(e) => {
        if (typeof modalRef.current !== 'undefined' && !modalRef.current.contains(e.target)) {
            setShowModal(false);
        }
    };
	
	useEffect(() => {
		document.addEventListener('keydown', onKeydown)
		return () => document.removeEventListener('keydown', onKeydown)
	  })

	useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

	const sendEmail = async () => {
		// setShowModal(true);
		if (name !== "" && email !== "" && comment !== ""){
			emailjs.sendForm('service_r0oujwz', 'template_77g0m8n', form.current, 'ic-g88gRWLxMcHbiq')
			.then((result) => {
				setName("");
				setEmail("");
				setComment("");
				setShowModal(true);
			}, (error) => {
				console.log(error.text);
			});
		} else {
			setAlert(true);
		}
		
	  }

	return (


	<div className="App">
		<BrowserRouter>
          <Routes>
            <Route path="/" element={<Main param='main' />}/> 
            <Route path="/about" element={<Main param='about' />}/> 
          </Routes>
        </BrowserRouter>
	</div>

	);

}

export default App;
