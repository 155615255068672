import React from 'react';
import { IconButton, Typography } from '@mui/material';

import logo from '../../logo.png';
import logoName from '../../logo_name.png';

import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';

function Header( {about, services, feedback, aboutRec} ) {
    return (
        <header className="App-header">
            <div className='header'>
                <div className='logo-div'>
                    <img src={logo} alt="RexTeam" className='logo'/>
                    <img src={logoName} alt="" className='logo-name'/>
                </div>	
                <div className="logo-menu">
                    <Typography className='font a'><Link className='font a' to='/#about'>О нас</Link></Typography>
                    <Typography className='font a'> <Link className='font a' to='/#services'>Услуги</Link></Typography>
                    <Typography className='font a'> <Link className='font a' to='/#feedback'>Обратная связь</Link></Typography>
                    <Typography className='font a'> <Link className='font a' to='/about'>Реквизиты</Link></Typography>
                </div>
                <div className='logo-contact'>
                    <a href="tel:+74152306083" className='a'><IconButton className='no-padding'><CallIcon className='icon'/><Typography className='icon'>306-083</Typography></IconButton></a>
                    <a href="https://wa.me/+79960344084"><IconButton className='no-padding'><WhatsAppIcon className='icon'/><Typography className='icon'>+7-996-034-40-84</Typography></IconButton></a>
                    <a href="mailto:main@rexteam.ru"><IconButton className='no-padding'><MailOutlineIcon className='icon'/><Typography className='icon'>main@rexteam.ru</Typography></IconButton></a>
                </div>

            </div>
        </header>
    )
}

export default Header;