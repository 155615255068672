import React from 'react';
import { IconButton, Typography } from '@mui/material';

import logo from '../../logo.png';
import logoName from '../../logo_name.png';
import cat from '../../pic/kot.jpg';


import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Header from '../Header';
import Footer from '../Footer';

function About() {
    return (
        <div>
            <div className='block-item'>
                <Typography variant='h4'>Наши реквизиты</Typography>
            </div>
            <div className='block-item-text' style={{width: '100%', alignItems: 'start'}}>
            ИП Пикалов Денис Владимирович.
            </div>
            <div className='block-item-text' style={{width: '100%', alignItems: 'start'}}>
                683002, Российская Федерация, г. Петропавловск-Камчатский, ул. Ларина, д. 21, кв. 35<br/>
                Почтовый адрес: 683016, г. Петропавловск-Камчатский, ул. Геологическая, д. 4, кв. 76<br/>
                ОГРНИП 321410000012325<br/> 
                ИНН 651500246891<br/>
                Банк: АО «Тинькофф Банк» 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26<br/>
                БИК 044525974<br/>
                Р/сч 40802810800002596935<br/>
                К/сч 30101810145250000974<br/>
                Тел. 8(4152)30-60-83<br/>
                WhatsApp: 89960344084<br/>
                E-mail: main@rexteam.ru<br/>
            </div>
        </div>

    )
}

export default About;