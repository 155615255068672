import React, {useState, useRef, useEffect} from 'react';


import './style.css';
import logo from '../../logo.png';
import logoName from '../../logo_name.png';
import logoNameColor from '../../pic/logo-name-green.png';
import logoGreen from '../../pic/logo-green.png';
import ugol from '../../pic/ugol.png';
import ugol2 from '../../pic/ugol2.png';
import cat from '../../pic/kot.jpg';
import kamchatka from '../../pic/kamchatka.jpg';
import russia from '../../pic/russia.jpg';
import { Button, IconButton, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from '../../components/Modal';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';



function Mobile({param}) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const form = useRef();
	const modalRef = useRef();
	const [showModal, setShowModal] = useState(false);

	
	const onKeydown = ({ key }) => {
		switch (key) {
		  case 'Escape':
			setShowModal(false);
			break
		  default: break
		}
	  }

	// const handleClickOutside = async(e) => {
    //     if (!modalRef.current.contains(e.target)) {
    //         setShowModal(false);
    //     }
    // };
	
	// useEffect(() => {
	// 	document.addEventListener('keydown', onKeydown)
	// 	return () => document.removeEventListener('keydown', onKeydown)
	//   })

	// useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // });

	const sendEmail = async () => {
		// setShowModal(true);
		emailjs.sendForm('service_r0oujwz', 'template_77g0m8n', form.current, 'ic-g88gRWLxMcHbiq')
		  .then((result) => {
			  setShowModal(true)
		  }, (error) => {
			  console.log(error.text);
		  });
	  }

	return (


	<div className="App">
		<header className="App-header">
			<div className='header-mobile'>
				<div className='logo-div'>
					<img src={logo} alt="RexTeam" className='logo'/>
					<img src={logoName} alt="" className='logo-name'/>
				</div>	
				<div className='logo-contact-mobile'>
					<a href="tel:+74152306083" className='a'><IconButton className='no-padding'><CallIcon className='icon-mobile'/><Typography className='icon-mobile'>306-083</Typography></IconButton></a>
					<a href="https://wa.me/+79960344084"><IconButton className='no-padding'><WhatsAppIcon className='icon-mobile'/><Typography className='icon-mobile'>+7-996-034-40-84</Typography></IconButton></a>
					<a href="mailto:main@rexteam.ru"><IconButton className='no-padding'><MailOutlineIcon className='icon-mobile'/><Typography className='icon-mobile'>main@rexteam.ru</Typography></IconButton></a>
				</div>
			</div>
            {/* <div className="logo-menu">
                <Typography className='font a' onClick={() => about.current.scrollIntoView({behavior: "smooth", block: "start"})}>1О нас</Typography>
                <Typography className='font a' onClick={() => services.current.scrollIntoView({behavior: "smooth", block: "start"})}>Услуги</Typography>
                <Typography className='font a' onClick={() => feedback.current.scrollIntoView({behavior: "smooth", block: "start"})}>Обратная связь</Typography>
            </div>	 */}
		</header>
		<div className='main'>
			<div className='banner-mobile'>
				<div className='banner-items-mobile'>
					<Typography className='banner-font-mobile'>ИНФОРМАЦИОННЫЕ ТЕХНОЛОГИИ <p/> НАША ПОМОЩЬ В ВАШЕМ РАЗВИТИИ</Typography>
					<img src={cat} alt="RexTeam" className='pic-mobile'/>
				</div>
			</div>
			{param == "main" ? 
            	<div className='main'>
					<div className='block-mobile' id="about">
						<div className='block-item'>
							<Typography variant='h5'>О нас</Typography>
						</div>
						<div className='block-item-text'>
							<Typography>Мы молодая, развивающаяся команда специалистов, готовые оказать консультацию и выполнить работы в сфере информационных технологий. </Typography>
						</div>
						<div className='block-item-text'>
							<Typography>Имеем опыт внедрения крупной распределенной информационной системы в сфере здравоохранения Камчатского края. Работаем с видеонаблюдением, организовываем и поддерживаем облачные сервисы, 
								разрабатываем сайты под ключ. </Typography>
						</div>
					</div>
					<div className="block-mobile" id="services">
						<Typography variant='h5'>Услуги</Typography>
						<div className='block-item-mobile'>
							<img src={kamchatka} alt="RexTeam" className='block-item-pic-mobile'/>
							<div>
								<Typography>Мы оказываем услуги по внедрению и развитию информационных технологий на Камчатке.</Typography>
								<List className='list-mobile'>
									<ListItem>
										<ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
										<ListItemText>Обеспечение работы сервисов предприятия.</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
										<ListItemText>Монтаж и сопровождение систем видеонаблюдения.</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
										<ListItemText>Настройка и сопровождение работы серверного и компьютерного оборудования.</ListItemText>
									</ListItem>
								</List>
							</div>
						</div>
						<div className='block-item-mobile'>
							<div>
								<img src={russia} alt="RexTeam" className='block-item-pic-mobile'/>
								<Typography>Работаем по всей России для реализации проектов:</Typography>
								<List className='list-mobile'>
									<ListItem>
										<ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
										<ListItemText>Разработка и сопровождение сайтов, Telegram и ВК - ботов.</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemIcon><ArrowForwardIosIcon/></ListItemIcon>
										<ListItemText>Настройка и сопровождение работы серверного оборудования.</ListItemText>
									</ListItem>
								</List>
							</div>
						</div>
					</div>
					<div className='block-mobile' id="feedback">	
						<Typography variant='h5'>Обратная связь</Typography>
						<Typography variant='subtitle1'>Напишите нам и мы с Вами свяжемся в ближайшее время</Typography>
						<form ref={form} className='block-feedback'>
							<div className='block-feedback'>
								<div className='block-feedback-item-mobile'>
									<Typography sx={{fontWeight: 'bold'}}>Ваше имя:</Typography>
									<TextField fullWidth className='block-feedback-textfield' name='user_name' value={name} onChange={(e) => setName(e.target.value)}></TextField>
								</div>
								<div className='block-feedback-item-mobile'>
									<Typography sx={{fontWeight: 'bold'}}>e-mail:</Typography>
									<TextField fullWidth className='block-feedback-textfield' name='user_email' value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
								</div>
								<div className='block-feedback-item-mobile'>
									<Typography sx={{fontWeight: 'bold'}} >Комментарий:</Typography>
									<TextField fullWidth multiline rows={6} className='block-feedback-textfield' name="message" value={comment} onChange={(e) => setComment(e.target.value)}></TextField>
								</div>
								<Button variant='contained' className='button' onClick={sendEmail} >Отправить</Button>
							</div>
							<div ref={modalRef}>
								{showModal && <Modal toggleModal={setShowModal}/>}
							</div>
						</form>
					</div>
				</div> :
			<div className='block-mobile'>	
				<div>
					<div className='block-item'>
						<Typography variant='h4'>Наши реквизиты</Typography>
					</div>
					<div className='block-item-text' style={{width: '100%', alignItems: 'start'}}>
					ИП Пикалов Денис Владимирович.
					</div>
					<div className='block-item-text' style={{width: '100%', alignItems: 'start'}}>
						683002, Российская Федерация, г. Петропавловск-Камчатский, ул. Ларина, д. 21, кв. 35<br/>
						Почтовый адрес: 683016, г. Петропавловск-Камчатский, ул. Геологическая, д. 4, кв. 76<br/>
						ОГРНИП 321410000012325<br/> 
						ИНН 651500246891<br/>
						Банк: АО «Тинькофф Банк» 127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26<br/>
						БИК 044525974<br/>
						Р/сч 40802810800002596935<br/>
						К/сч 30101810145250000974<br/>
						Тел. 8(4152)30-60-83<br/>
						WhatsApp: 89960344084<br/>
						E-mail: main@rexteam.ru<br/>
					</div>
				</div>
			</div> 
			}
		</div>
		<footer className='footer-background'>
			<img src={ugol} alt="RexTeam" className='footer-logo'/>

            <div className='footer-mobile'>
                <div className='footer-block'>
                    <div className='footer-block-item'>
                        <div className='logo-div'>
                            <img src={logoGreen} alt="RexTeam" className='footer-logo-mobile'/>
                            <img src={logoNameColor} alt="" className='footer-logo-name-mobile'/>
                        </div>	
                    </div>
                </div>
                <div className='footer-block' >
                    <div className='footer-block-item-a'>
						<Link className='a' to='/#about'>О нас</Link>
                    </div>
                    <div className='footer-block-item-a'>
					<Link className='a' to='/#services'>Услуги</Link>
                    </div>
                    
                </div>
                <div className='footer-block'>
                    <div className='footer-block-item-a' >
						<Link className='a' to='/#feedback'>Обратная связь</Link>
                    </div>
					<div className='footer-block-item-a' >
						<Link className='a' to='/about'>Реквизиты</Link>
                    </div>
                    <div className='footer-block-item'>
                        <a href="/docs/oferta.pdf" download className='a'>Договор оферты</a>
                    </div>
                </div>
                
            </div> 
			<div className='ugol2'>
				<img src={ugol2} alt="RexTeam" className='footer-logo'/>
			</div>
			
        </footer>
		</div>
	);

}

export default Mobile;
